.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  padding-bottom: 80px;
}

.site-content {
  flex: 1;
}

.member-status-panel {
  background-color: #d8d8d8;
  border-radius: 0;
}

.input-read-only {
  background: #dcdcdc;
}
